import { useBasketStore } from '~/stores/basket';

export function useCheckoutRedirect() {
    const basketStore = useBasketStore();
    const loaded = computed(() => {
        return basketStore.loaded;
    });
    function checkAndRedirect() {
        if (!basketStore.lines.length) {
            navigateTo('/');
        }
    }
    if (!loaded.value) {
        watch(loaded, () => {
            if (loaded) {
                checkAndRedirect();
            }
        });
    } else {
        checkAndRedirect();
    }
}
